/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */ import React from "react";
/* eslint-disable */ import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux'
import store from 'redux/reducers'
// core components
import Auth from "views/AuthPage/AuthPage";
import Terms from "views/Terms/Terms";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import "assets/css/custom.css";
require('dotenv').config()

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
  <SnackbarProvider maxSnack={3}>
    <Router history={hist}>
      <Switch>
        <Route path="/terms" component={Terms} />

        <Route path="/" component={Auth} />

      </Switch>
    </Router>
  </SnackbarProvider>
  </Provider>,
  document.getElementById("root")
);
