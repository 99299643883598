/* eslint-disable */ import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Email from "@material-ui/icons/Email";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {
  passwordReset,
  encyrptSecret,
  requestPasswordReset,
  decryptSecret,
} from "services/UserManagement.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import sha256 from "sha256";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { useSelector } from "react-redux";

const useStyles = makeStyles(styles);

function SecretReset(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 400);

  const loadingMessage = useSelector((state) => state.LoadingMessage);

  const [previousRoute, setPreviousRoute] = useState(props.previousRoute);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [answer, setAnswer] = useState("");

  const [loading, setLoading] = useState(false);
  //hover and show 2
  const [hoverPassword2, setHoverPassword2] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: "pointer" });

  //Errors
  const [otpError, setOtpError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [answerError, setAnswerError] = useState(false);

  const [countDown, setCountDown] = useState(new Date().getTime() + 60000);
  const [seconds, setSeconds] = useState("");
  const [showSeconds, setShowSeconds] = useState(true);

  const [showSendOtpButton, setShowSendOtpButton] = useState(false);

  const classes = useStyles();

  const { ...rest } = props;
  const { enqueueSnackbar } = useSnackbar();

  const count = async () => {
    console.log(countDown);
    var x = setInterval(async function() {
      // Get today's date and time
      const now = new Date().getTime();
      // Find the distance between now and the count down date
      const distance = countDown - now;
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
      // If the count down is over, write some text
      if (distance < 1) {
        setSeconds("");
        setShowSeconds(false);
        setShowSendOtpButton(true);
        clearInterval(x);
      }
    }, 500);
  };

  const requestOTP = async () => {
    const response = await requestPasswordReset(props.user.alias);
    switch (response.code) {
      case 200:
        setShowSeconds(true);
        setShowSendOtpButton(false);
        setCountDown(new Date().getTime() + 60000);
        break;
      case 201:
        count();
        enqueueSnackbar("Account Not Found", { variant: "warning" });
        break;
      case 202:
        enqueueSnackbar("High Privacy Account Cannot Reset Password", {
          variant: "warning",
        });
        break;
      case 400:
        enqueueSnackbar("Account Info Retrieval Failed", { variant: "error" });
        break;
      case null:
        enqueueSnackbar("Account Info Retrieval Failed", { variant: "error" });
    }
  };
  const ResetPassword = async (event) => {
    event.preventDefault();
    //required check
    setOtpError(otp === "" ? true : false);
    setPasswordError(password === "" ? true : false);
    setAnswerError(answer === "" ? true : false);

    if (otp != "" && password != "" && answer != "") {
      setLoading(true);
      try {
        const secretKey = decryptSecret(
          props.user.encryptedRecoverySecret,
          sha256(answer.toLowerCase())
        );
        const encryptedSecret = encyrptSecret(secretKey, sha256(password));

        const response = await passwordReset(
          secretKey,
          encryptedSecret,
          otp,
          props.merchantKey
        );
        switch (response) {
          case 200:
            enqueueSnackbar("Password Reset Succeeded", { variant: "success" });
            // props.history.push(previousRoute);

            if (props.serviceType && props.serviceType == "0") {
              props.setStep("SIGN");
            } else if (props.serviceType && props.serviceType == "1") {
              const token = localStorage.getItem("token");
              opener.postMessage(token, props.origin);
              window.close();
            } else {
              const token = localStorage.getItem("token")
                ? "?token=" + localStorage.getItem("token")
                : "";
              window.location.assign(props.redirectUrl + token);
            }
            // const token = localStorage.getItem("token") ? "?token=" + localStorage.getItem("token") : "";
            // window.location.assign(props.redirectUrl + token);
            break;
          case 201:
            enqueueSnackbar("Account Not Found", { variant: "warning" });
            break;
          case 202:
            enqueueSnackbar("OTP is Invalid", { variant: "warning" });
            setOtpError(true);
            break;
          case 203:
            enqueueSnackbar("Insufficient Fund in Account", {
              variant: "warning",
            });
            break;
          case 204:
            enqueueSnackbar("Password Reset Failed", { variant: "error" });
            break;
          case null:
            enqueueSnackbar("Password Reset Failed", { variant: "error" });
        }
      } catch (err) {
        enqueueSnackbar("Recovery Email is Incorrect", { variant: "error" });
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    count();
  }, [countDown]);
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <Card className={classes[cardAnimaton]}>
            <form
              className={classes.form}
              onSubmit={ResetPassword}
              style={
                loading
                  ? {
                      filter: "blur(1px)",
                      "-webkit-filter": "blur(1px)",
                    }
                  : null
              }
            >
              <CardHeader color="info" className={classes.cardHeader}>
                <h3>Password Reset</h3>
              </CardHeader>
              <CardBody>
                <div
                  style={
                    loading
                      ? {
                          filter: "blur(1px)",
                          "-webkit-filter": "blur(1px)",
                        }
                      : null
                  }
                >
                  {" "}
                  {showSeconds && (
                    <SnackbarContent
                      message={
                        "An OTP has been sent to your email, Use it within " +
                        seconds +
                        " seconds"
                      }
                      icon={AddAlert}
                      color="warning"
                    />
                  )}
                  {showSendOtpButton && (
                    <Button onClick={requestOTP}>Resend OTP</Button>
                  )}
                  <CustomInput
                    error={otpError}
                    labelText="OTP *"
                    id="otp"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      onChange: function(e) {
                        var token = /^\d\d\d\d\d\d$/;
                        setOtp(e.target.value);
                        setOtpError(
                          e.target.value === "" || !token.test(e.target.value)
                            ? true
                            : false
                        );
                      },
                      value: otp,
                    }}
                  />
                  {props.user.recoveryQuestion}
                  <CustomInput
                    error={answerError}
                    labelText="Recovery Email *"
                    id="recoveryEmail"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      onChange: function(e) {
                        setAnswer(e.target.value);
                        setAnswerError(e.target.value === "" ? true : false);
                      },
                      value: answer,
                    }}
                  />
                  <CustomInput
                    //info
                    error={passwordError}
                    labelText="New Password *"
                    id="newPassword"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: !showPassword2 ? "password" : "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          {hoverPassword2 && (
                            <Icon
                              style={linkStyle}
                              onMouseEnter={() => {
                                setHoverPassword2(true);
                              }}
                              onMouseLeave={() => {
                                setHoverPassword2(false);
                              }}
                              className={classes.inputIconsColor}
                              onClick={() => {
                                setShowPassword2(!showPassword2);
                              }}
                            >
                              {showPassword2 ? "visibilityoff" : "visibility"}
                            </Icon>
                          )}
                          {!hoverPassword2 && (
                            <Icon
                              style={linkStyle}
                              className={classes.inputIconsColor}
                              onMouseEnter={() => {
                                setHoverPassword2(true);
                              }}
                              onMouseLeave={() => {
                                setHoverPassword2(false);
                              }}
                            >
                              lock
                            </Icon>
                          )}
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      onChange: function(e) {
                        setPassword(e.target.value);
                        setPasswordError(e.target.value === "" ? true : false);
                      },
                      value: password,
                    }}
                  />{" "}
                </div>

                {loading && <LinearProgress />}
                {loading && <p className={classes.divider}>{loadingMessage}</p>}
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button
                  color="info"
                  size="lg"
                  type={"submit"}
                  onClick={ResetPassword}
                  disabled={loading || otpError || passwordError}
                >
                  Reset Password
                </Button>
              </CardFooter>
              <Typography
                gutterBottom
                variant="body2"
                color="textSecondary"
                align={"center"}
              >
                Try a different method?
                <Button
                  simple
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setOption("SECRETRESET");
                  }}
                >
                  Reset with secretKey
                </Button>
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                color="textSecondary"
                align={"center"}
              >
                Change your mind?
                <Button
                  simple
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setStep("LOGIN");
                  }}
                >
                  Login
                </Button>
              </Typography>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default SecretReset;
