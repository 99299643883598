/* eslint-disable */
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import Login from "views/AuthPage/Components/Login.js";
import SecretLogin from "views/AuthPage/Components/SecretLogin.js";
import RegisterLow from "views/AuthPage/Components/RegisterLow.js";
import RegisterMedium from "views/AuthPage/Components/RegisterMedium.js";
import RegisterHigh from "views/AuthPage/Components/RegisterHigh.js";
import Privacy from "views/AuthPage/Components/Privacy.js";
// import KYC from "views/AuthPage/Components/KYC.js";
import GridContainer from "components/Grid/GridContainer.js";
import BlockchainCredentials from "views/AuthPage/Components/BlockchainCredentials.js";
import Account from "views/AuthPage/Components/Account";
import PasswordReset from "views/AuthPage/Components/PasswordReset";
import { getProjectByPublicKey } from "services/UserManagement.js";
import InvalidProject from "views/AuthPage/Components/InvalidProject";
import IncompleteSetup from "views/AuthPage/Components/IncompleteSetup";
import GiveAccess from "views/AuthPage/Components/GiveAccess";
import Approve from "views/AuthPage/Components/Approve";

import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
// import { Step } from "@material-ui/core";
import jwt from "jsonwebtoken";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function AuthPage(props) {
  const [step, setStep] = useState("LOADING");
  const [previousRoute, setPreviousRoute] = useState("/");
  const [secretKey, setSecretKey] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [ethSecretKey, setEthSecretKey] = useState("");
  const [ethPublicKey, setEthPublicKey] = useState("");
  const [alias, setAlias] = useState("");
  const [type, setType] = useState("1");
  const [warning, setWarning] = useState("");

  const [origin, setOrigin] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [xdr, setXDR] = useState("");
  const [merchantKey, setMerchantKey] = useState("");
  const [userKey, setUserKey] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [signInMethod, setSignInMethod] = useState("GLOBAL");
  const [projectName, setProjectName] = useState("");
  const [projectLogo, setProjectLogo] = useState("");

  const classes = useStyles();
  const classes2 = useStyles2();

  const { ...rest } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        //niftron project key - the project key for any merchant
        const projectKey = getParameterByName("projectKey");
        if (projectKey != null && projectKey != "") {
          console.log(projectKey);
          setMerchantKey(decodeURI(projectKey));
        }

        const project = await getProjectByPublicKey(decodeURI(projectKey));
        if (project == null) {
          setStep("INVALIDPROJECT");
          return;
        }

        setProjectName(project.name);
        setProjectLogo(project.projectLogoURL);

        if (!project.authentication || project.authentication == undefined) {
          setStep("INCOMPLETESETUP");
          return;
        }
        setRedirectUrl(project.authentication.redirectURL);
        setType(project.authentication.userAccountType);
        setWarning(project.authentication.warningMessage);
        setSignInMethod(project.authentication.signInMethod);

        const serviceType = getParameterByName("serviceType");
        if (serviceType != null && serviceType != "") {
          console.log(decodeURI(serviceType));
          setServiceType(decodeURI(serviceType));
        }

        const xdr = getParameterByName("xdr");
        if (xdr != null && xdr != "") {
          console.log(decodeURI(xdr));
          setXDR(decodeURI(xdr));
        }

        const origin = getParameterByName("origin");
        if (origin != null && origin != "") {
          console.log(decodeURI(origin));
          setOrigin(decodeURI(origin));
        }

        const token = localStorage.getItem("token")
          ? localStorage.getItem("token")
          : "";

        const decodedToken = jwt.decode(token);
        if (decodedToken == null) {
          setStep("LOGIN");
          return;
        }
        if (decodedToken.exp > Math.floor(new Date().getTime() / 1000.0)) {
          setAlias(decodedToken.alias);
          if (serviceType != null && serviceType == "0") {
            setStep("SIGN");
            return;
          } else {
            setStep("GIVEACCESS");
            return;
          }
        } else {
          localStorage.removeItem("secretKey");
          localStorage.removeItem("token");
          setStep("LOGIN");
          return;
        }

        // //niftron merchant key - the creator key for any merchant
        // const merchant = getParameterByName('merchantKey')
        // if (merchant != null && merchant != "") {
        //   setMerchantKey(decodeURI(merchant))
        // }

        // //redirectUrl - on success redirect url
        // const redirect = getParameterByName('redirectUrl')
        // if (redirect != null && redirect != "") {
        //   setRedirectUrl(decodeURI(redirect))
        // }

        // //user account type - 0/1
        // const type = getParameterByName('type')
        // if (type != null && type != "") {
        //   setType(decodeURI(type))
        // }

        // //any special message or warning to user
        // const warning = getParameterByName('warning')
        // if (warning != null && warning != "") {
        //   setWarning(decodeURI(warning))
        // }

        // console.log(merchant, redirect, type, warning)
      } catch (e) {
        console.log(e);
        setStep("INCOMPLETESETUP");
      }
    };

    document.body.style.overflow = "scroll";
    fetchData();
  }, []);

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  return (
    <div>
      <div className={classes.container}>
        <GridContainer justify="center">
          <Link to={"/"}>
            <img
              height="50px"
              style={{ margin: "50px 0px 50px 0px" }}
              src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+FULL"
            />
          </Link>
        </GridContainer>
        {step === "LOGIN" && (
          <Login
            previousRoute={previousRoute}
            setStep={setStep}
            signInMethod={signInMethod}
            projectName={projectName}
            history={props.history}
            warning={warning}
            redirectUrl={redirectUrl}
            merchantKey={merchantKey}
            serviceType={serviceType}
            xdr={xdr}
            origin={origin}
          />
        )}
        {step === "SECRETLOGIN" && (
          <SecretLogin
            previousRoute={previousRoute}
            setStep={setStep}
            signInMethod={signInMethod}
            projectName={projectName}
            history={props.history}
            warning={warning}
            redirectUrl={redirectUrl}
            merchantKey={merchantKey}
            serviceType={serviceType}
            xdr={xdr}
            origin={origin}
          />
        )}
        {/* {step === 'ACCOUNT' && (
          <Account setStep={setStep} setType={setType} history={props.history} />
        )} */}
        {step === "PRIVACY" && (
          <Privacy
            setStep={setStep}
            history={props.history}
            projectName={projectName}
          />
        )}
        {step === "REGISTERLOW" && (
          <RegisterLow
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            signInMethod={signInMethod}
            history={props.history}
            setSecretKey={setSecretKey}
            setPublicKey={setPublicKey}
            setEthSecretKey={setEthSecretKey}
            setEthPublicKey={setEthPublicKey}
            projectName={projectName}
            setAlias={setAlias}
            redirectUrl={redirectUrl}
            merchantKey={merchantKey}
            serviceType={serviceType}
            xdr={xdr}
            origin={origin}
          />
        )}
        {step === "REGISTERMEDIUM" && (
          <RegisterMedium
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            signInMethod={signInMethod}
            history={props.history}
            setSecretKey={setSecretKey}
            setPublicKey={setPublicKey}
            setEthSecretKey={setEthSecretKey}
            setEthPublicKey={setEthPublicKey}
            projectName={projectName}
            setAlias={setAlias}
            redirectUrl={redirectUrl}
            merchantKey={merchantKey}
            serviceType={serviceType}
            xdr={xdr}
            origin={origin}
          />
        )}
        {step === "REGISTERHIGH" && (
          <RegisterHigh
            previousRoute={previousRoute}
            setStep={setStep}
            type={type}
            signInMethod={signInMethod}
            history={props.history}
            setSecretKey={setSecretKey}
            setPublicKey={setPublicKey}
            setEthSecretKey={setEthSecretKey}
            setEthPublicKey={setEthPublicKey}
            projectName={projectName}
            setAlias={setAlias}
            redirectUrl={redirectUrl}
            merchantKey={merchantKey}
            serviceType={serviceType}
            xdr={xdr}
            origin={origin}
          />
        )}
        {step === "BLOCKCHAIN" && (
          <BlockchainCredentials
            previousRoute={previousRoute}
            setStep={setStep}
            history={props.history}
            secretKey={secretKey}
            publicKey={publicKey}
            ethSecretKey={ethSecretKey}
            ethPublicKey={ethPublicKey}
            projectName={projectName}
            alias={alias}
            redirectUrl={redirectUrl}
            serviceType={serviceType}
            xdr={xdr}
            origin={origin}
          />
        )}
        {step === "PASSWORDRESET" && (
          <PasswordReset
            previousRoute={previousRoute}
            setStep={setStep}
            signInMethod={signInMethod}
            projectName={projectName}
            redirectUrl={redirectUrl}
            history={props.history}
            merchantKey={merchantKey}
            serviceType={serviceType}
            xdr={xdr}
            origin={origin}
          />
        )}
        {step === "GIVEACCESS" && (
          <GiveAccess
            history={props.history}
            projectName={projectName}
            redirectUrl={redirectUrl}
            alias={alias}
            type={type}
            signInMethod={signInMethod}
            merchantKey={merchantKey}
            setStep={setStep}
            projectLogo={projectLogo}
            serviceType={serviceType}
            xdr={xdr}
            origin={origin}
          />
        )}
        {step === "SIGN" && (
          <Approve
            history={props.history}
            projectName={projectName}
            redirectUrl={redirectUrl}
            alias={alias}
            type={type}
            signInMethod={signInMethod}
            merchantKey={merchantKey}
            setStep={setStep}
            projectLogo={projectLogo}
            serviceType={serviceType}
            xdr={xdr}
            origin={origin}
          />
        )}
        {step === "INVALIDPROJECT" && (
          <InvalidProject history={props.history} />
        )}
        {step === "INCOMPLETESETUP" && (
          <IncompleteSetup history={props.history} />
        )}
        {step === "LOADING" && (
          <Backdrop className={classes2.backdrop} open={true}>
            <img src="/favicon.png" height="200" id="breathing-logo" />
          </Backdrop>
        )}
      </div>
      {step != "LOADING" && (
        <Footer
        //  whiteFont
        />
      )}
    </div>
  );
}

export default AuthPage;
