/* eslint-disable */
import React, { useState, useEffect } from "react";
import StellarSdk from "stellar-sdk";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import Select from "@material-ui/core/Select";

import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputSelect from "components/CustomInputSelect/CustomInputSelect.js";
import { green, red } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  register,
  checkEmail,
  checkAlias,
  registerLiveAccount,
} from "services/UserManagement.js";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useSelector } from "react-redux";

var setup = require("hsimp-purescript");
var characterSets = require("hsimp-purescript/dictionaries/character-sets.json");
var periods = require("hsimp-purescript/dictionaries/periods.json");
var namednumbers = require("hsimp-purescript/dictionaries/named-numbers.json");
var top10k = require("hsimp-purescript/dictionaries/top10k.json");
var patterns = require("hsimp-purescript/dictionaries/patterns.json");
var checks = require("hsimp-purescript/dictionaries/checks.json");
const Keypair = StellarSdk.Keypair;

const useStyles = makeStyles(styles);

function RegisterLow(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 400);
  const loadingMessage = useSelector((state) => state.LoadingMessage);

  const [passwordStrength, setPasswordStrength] = useState("a second");

  const [type, setType] = useState(props.type);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recoveryQuestion, setRecoveryQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [loading, setLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  //hover and show
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [hoverPassword, setHoverPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: "pointer" });

  //Errors
  const [typeError, setTypeError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [recoveryQuestionError, setRecoveryQuestionError] = useState(false);
  const [answerError, setAnswerError] = useState(false);

  //Check Values
  const [checkNameError, setCheckNameError] = useState(false);
  const [checkNameSuccess, setCheckNameSuccess] = useState(false);

  const [checkEmailError, setCheckEmailError] = useState(false);
  const [checkEmailSuccess, setCheckEmailSuccess] = useState(false);

  const checkStrength = () => {
    const hsimp = setup({
      calculation: {
        calcs: 40e9,
        characterSets: characterSets,
      },
      time: {
        periods: periods,
        namedNumbers: namednumbers,
        forever: "Forever",
        instantly: "an Instant",
      },
      checks: {
        dictionary: top10k,
        patterns: patterns,
        messages: checks,
      },
    });

    setPasswordStrength(hsimp(password).time);
  };

  const focusPassword = () => {
    setPasswordFocus(true);
  };

  const blurPassword = () => {
    setPasswordFocus(false);
  };
  const classes = useStyles();

  const { ...rest } = props;
  const { enqueueSnackbar } = useSnackbar();

  const ProceedRegistrationLow = async (event) => {
    event.preventDefault();
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //required check
    setTypeError(type === "" ? true : false);
    setNameError(name === "" ? true : false);
    setEmailError(email === "" || !re.test(email) ? true : false);
    setPasswordError(password === "" ? true : false);
    setConfirmPasswordError(
      confirmPassword === "" || password != confirmPassword ? true : false
    );
    setRecoveryQuestionError(recoveryQuestion === "" ? true : false);
    setAnswerError(answer === "" ? true : false);

    // }
    if (
      type != "" &&
      name != "" &&
      email != "" &&
      password != "" &&
      confirmPassword != "" &&
      recoveryQuestion != "" &&
      answer != "" &&
      password === confirmPassword
    ) {
      setLoading(true);
      let keypair = Keypair.random();

      //Ethereum keypair
      const Web3EthAccounts = require("web3-eth-accounts");
      const account = new Web3EthAccounts("https://cloudflare-eth.com");
      const ethKeypair = account.create();

      //Set to Parent
      props.setPublicKey(keypair.publicKey());
      props.setSecretKey(keypair.secret());
      props.setEthPublicKey(ethKeypair.address);
      props.setEthSecretKey(ethKeypair.privateKey);

      if (
        props.merchantKey ==
        "GDWZSP6TIO6W6PM5F3R4FNKDOKIWFMT2UYTRXBI3J4AHMGMI42MUFAHF"
      ) {
        const response = await registerLiveAccount(
          type,
          name,
          email,
          password,
          recoveryQuestion,
          answer,
          keypair,
          ethKeypair,
          "0",
          props.signInMethod && props.signInMethod == "PROJECT"
            ? props.merchantKey
            : undefined
        );

        switch (response) {
          case 200:
            enqueueSnackbar("Registered Successfully", { variant: "success" });
            props.setStep("BLOCKCHAIN");
            break;
          case 201:
            enqueueSnackbar("Email Already Exists", { variant: "warning" });
            setEmailError(true);
            break;
          case 202:
            enqueueSnackbar("Alias Already Exists", { variant: "warning" });
            setNameError(true);
            break;
          case null:
            enqueueSnackbar("Registration Failed", { variant: "error" });
        }
      } else {
        const response = await register(
          type,
          name,
          email,
          password,
          recoveryQuestion,
          answer,
          keypair,
          ethKeypair,
          "0",
          props.signInMethod && props.signInMethod == "PROJECT"
            ? props.merchantKey
            : undefined
        );

        switch (response) {
          case 200:
            enqueueSnackbar("Registered Successfully", { variant: "success" });
            props.setStep("BLOCKCHAIN");
            break;
          case 201:
            enqueueSnackbar("Email Already Exists", { variant: "warning" });
            setEmailError(true);
            break;
          case 202:
            enqueueSnackbar("Alias Already Exists", { variant: "warning" });
            setNameError(true);
            break;
          case null:
            enqueueSnackbar("Registration Failed", { variant: "error" });
        }
      }
      setLoading(false);
    }
  };
  const AliasMuch = async (value) => {
    if (value === name) {
      setNameLoading(true);
      const check = await checkAlias(value);
      if (check != null && !check.isAvailable) {
        setCheckNameError(true);
        setCheckNameSuccess(false);
      } else {
        setCheckNameSuccess(true);
        setCheckNameError(false);
      }
      setNameLoading(false);
    }
  };
  const EmailMuch = async (value) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === email && re.test(value)) {
      setEmailLoading(true);
      const check = await checkEmail(value);
      if (check != null && !check.isAvailable) {
        setCheckEmailError(true);
        setCheckEmailSuccess(false);
      } else {
        setCheckEmailSuccess(true);
        setCheckEmailError(false);
      }
      setEmailLoading(false);
    }
  };
  const aliasAPIDebounced = AwesomeDebouncePromise(AliasMuch, 500);
  const emailAPIDebounced = AwesomeDebouncePromise(EmailMuch, 500);

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <Card className={classes[cardAnimaton]}>
            <form className={classes.form} onSubmit={ProceedRegistrationLow}>
              <CardHeader color="info" className={classes.cardHeader}>
                <h3>REGISTRATION</h3>
              </CardHeader>
              <CardBody>
                <div
                  style={
                    loading
                      ? {
                          filter: "blur(1px)",
                          "-webkit-filter": "blur(1px)",
                        }
                      : null
                  }
                >
                  {" "}
                  <CustomInput
                    //info
                    success={checkNameSuccess}
                    error={nameError || checkNameError}
                    labelText="Alias *"
                    id="alias"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          {nameLoading && (
                            <CircularProgress size={24} thickness={4} />
                          )}
                          {!nameLoading && checkNameSuccess && (
                            <CheckCircleOutlineIcon
                              style={{ color: green[500] }}
                            />
                          )}
                          {!nameLoading && checkNameError && (
                            <HighlightOffIcon style={{ color: red[500] }} />
                          )}
                          <People className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      onChange: async function(e) {
                        setName(e.target.value);
                        setNameError(e.target.value === "" ? true : false);
                        props.setAlias(e.target.value);

                        await aliasAPIDebounced(e.target.value);
                      },
                      onKeyUp: async function(e) {
                        await aliasAPIDebounced(e.target.value);
                      },
                      value: name,
                    }}
                  />
                  <CustomInput
                    //info
                    success={checkEmailSuccess}
                    error={emailError || checkEmailError}
                    labelText="Email *"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          {emailLoading && (
                            <CircularProgress size={24} thickness={4} />
                          )}
                          {!emailLoading && checkEmailSuccess && (
                            <CheckCircleOutlineIcon
                              style={{ color: green[500] }}
                            />
                          )}
                          {!emailLoading && checkEmailError && (
                            <HighlightOffIcon style={{ color: red[500] }} />
                          )}
                          <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      required: true,
                      onChange: async function(e) {
                        setEmail(e.target.value);
                        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        setEmailError(
                          e.target.value === "" || !re.test(e.target.value)
                            ? true
                            : false
                        );

                        await emailAPIDebounced(e.target.value);
                      },
                      onKeyUp: async function(e) {
                        await emailAPIDebounced(e.target.value);
                      },
                      value: email,
                    }}
                  />
                  {passwordFocus &&
                    password.length > 0 &&
                    `It takes ${passwordStrength} to crack your password!`}
                  <CustomInput
                    //info
                    error={passwordError}
                    labelText="Password *"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: !showPassword ? "password" : "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          {hoverPassword && (
                            <Icon
                              style={linkStyle}
                              onMouseEnter={() => {
                                setHoverPassword(true);
                              }}
                              onMouseLeave={() => {
                                setHoverPassword(false);
                              }}
                              className={classes.inputIconsColor}
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? "visibilityoff" : "visibility"}
                            </Icon>
                          )}
                          {!hoverPassword && (
                            <Icon
                              style={linkStyle}
                              className={classes.inputIconsColor}
                              onMouseEnter={() => {
                                setHoverPassword(true);
                              }}
                              onMouseLeave={() => {
                                setHoverPassword(false);
                              }}
                            >
                              lock
                            </Icon>
                          )}
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      onChange: function(e) {
                        setPassword(e.target.value);
                        checkStrength();
                        setPasswordError(e.target.value === "" ? true : false);
                      },
                      onFocus: focusPassword,
                      onBlur: blurPassword,
                      value: password,
                    }}
                  />
                  <CustomInput
                    //info
                    error={confirmPasswordError}
                    labelText="Confirm Password *"
                    id="confirmPassword"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>lock</Icon>
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      onChange: function(e) {
                        setConfirmPassword(e.target.value);
                        setConfirmPasswordError(
                          e.target.value === "" || password != e.target.value
                            ? true
                            : false
                        );
                      },
                      value: confirmPassword,
                    }}
                  />
                  <CustomInput
                    //info
                    error={answerError}
                    labelText="Recovery Email *"
                    id="answer"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      onChange: function(e) {
                        setAnswer(e.target.value);
                        setAnswerError(e.target.value === "" ? true : false);

                        let clue = e.target.value.substring(0, 3);
                        let remainingLength = e.target.value.substring(3)
                          .length;
                        let placeholder = "";
                        for (let i = 0; i < remainingLength; i++) {
                          placeholder += "*";
                        }
                        let question = `Enter your recovery email that starts with ${clue +
                          placeholder}`;
                        //console.log(question)
                        setRecoveryQuestion(question);
                      },
                      value: answer,
                    }}
                  />
                </div>

                {loading && <LinearProgress />}
                {loading && <p className={classes.divider}>{loadingMessage}</p>}
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button
                  color="info"
                  size="lg"
                  type={"submit"}
                  onClick={ProceedRegistrationLow}
                  disabled={
                    loading ||
                    nameLoading ||
                    emailLoading ||
                    typeError ||
                    nameError ||
                    emailError ||
                    passwordError ||
                    confirmPasswordError ||
                    answerError ||
                    checkNameError
                  }
                >
                  Register{" "}
                </Button>
              </CardFooter>
              <Typography
                gutterBottom
                variant="body2"
                color="textSecondary"
                align={"center"}
              >
                Change your mind?
                <Button
                  simple
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setStep("PRIVACY");
                  }}
                >
                  Go Back
                </Button>
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                color="textSecondary"
                align={"center"}
              >
                Already a niftroner?
                <Button
                  simple
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setStep("LOGIN");
                  }}
                >
                  Login
                </Button>
              </Typography>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default RegisterLow;
