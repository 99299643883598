/* eslint-disable */ import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
const useStyles = makeStyles(styles);
function IncompleteSetup(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 400);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={9} >
          <Card className={classes[cardAnimaton]}>
            <CardHeader color="info" className={classes.cardHeader}>
              <h3>Project Setup is Incomplete</h3>
            </CardHeader>
            <CardBody>
              <Typography gutterBottom variant="body1" color="textSecondary" component="span">
                <p className={classes.divider}>Please complete the Authentication Setup in the <a target="_blank" href={"https://console.niftron.com"}>Niftron Developer Console</a> </p>
              </Typography>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>

  );
}

export default IncompleteSetup