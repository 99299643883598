/* eslint-disable */ import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { NiftronDistributorPublicKey } from "variables/constants";
import Typography from "@material-ui/core/Typography";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import jwt from "jsonwebtoken";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(styles);
function GiveAccess(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 400);

  const classes = useStyles();
  const [canProceed, setCanProceed] = React.useState(false);
  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      const decodedToken = jwt.decode(token);
      if (
        props.signInMethod == "PROJECT" &&
        decodedToken.merchant == props.merchantKey
      ) {
        setCanProceed(true);
      }

      if (
        props.signInMethod == "GLOBAL" &&
        decodedToken.merchant == NiftronDistributorPublicKey
      ) {
        setCanProceed(true);
      }
    }
  }, []);

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={9}>
          <Card className={classes[cardAnimaton]}>
            <CardHeader color="info" className={classes.cardHeader}>
              <h3>
                {`Continue as `}
                <b>{props.alias}</b>
              </h3>
            </CardHeader>
            <CardBody>
              <CardFooter className={classes.cardFooter}>
                {/* <Avatar alt={props.alias} /> */}
                <Avatar
                  variant="square"
                  alt={props.projectName}
                  src={props.projectLogo}
                  style={{ width: 100, height: 100 }}
                />
              </CardFooter>
              <Typography
                gutterBottom
                variant="body1"
                color="textSecondary"
                component="span"
              >
                <p className={classes.divider}>
                  <b>{props.projectName}</b>
                  {` will receive your niftron session.`}
                </p>
              </Typography>
            </CardBody>

            <CardFooter className={classes.cardFooter}>
              <Button
                simple
                color="info"
                size="lg"
                onClick={() => {
                  props.setStep("LOGIN");
                }}
              >
                Use another account{" "}
              </Button>
              <Button
                color="info"
                size="lg"
                disabled={!canProceed}
                onClick={() => {
                  if (props.serviceType && props.serviceType == "1") {
                    const token = localStorage.getItem("token");
                    opener.postMessage(token, props.origin);
                    window.close();
                  } else {
                    const token = localStorage.getItem("token")
                      ? "?token=" + localStorage.getItem("token")
                      : "";
                    window.location.assign(props.redirectUrl + token);
                  }
                }}
              >
                Continue{" "}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default GiveAccess;
